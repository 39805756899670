/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    Analyticsv1EmptyResponse,
    ListSessionsResponseSession,
    RpcStatus,
    V1BlockResponse,
    V1Change2FAResponse,
    V1ConfirmRequest,
    V1ConfirmResponse,
    V1GetZendeskArticleResponse,
    V1GetZendeskImageResponse,
    V1GetZendeskJWTResponse,
    V1LoginRequest,
    V1LoginResponse,
    V1LogoutRequest,
    V1LogoutResponse,
    V1RefreshTokensRequest,
    V1RefreshTokensResponse,
    V1ResendTokenResponse,
    V1ResetOTPResponse,
    V1ResetPasswordRequest,
    V1ResetPasswordResponse,
    V1SetPasswordRequest,
    V1SetPasswordResponse,
    V1UnblockResponse,
    V1ValidateUserRequest,
    V1ValidateUserResponse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Auth<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags AuthService
     * @name AuthServiceChange2Fa
     * @request PATCH:/auth/2fa/{user_id}
     * @secure
     */
    authServiceChange2Fa = (
        userId: string,
        query?: {
            is_2fa_mandatory?: boolean;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1Change2FAResponse, RpcStatus>({
            path: `/auth/2fa/${userId}`,
            method: "PATCH",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AuthService
     * @name AuthServiceLogin
     * @request POST:/auth/login
     */
    authServiceLogin = (body: V1LoginRequest, params: RequestParams = {}) =>
        this.request<V1LoginResponse, RpcStatus>({
            path: `/auth/login`,
            method: "POST",
            body: body,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AuthService
     * @name AuthServiceLogout
     * @request POST:/auth/logout
     * @secure
     */
    authServiceLogout = (body: V1LogoutRequest, params: RequestParams = {}) =>
        this.request<V1LogoutResponse, RpcStatus>({
            path: `/auth/logout`,
            method: "POST",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AuthService
     * @name AuthServiceResetPassword
     * @request POST:/auth/password:reset
     */
    authServiceResetPassword = (
        reset: string,
        body: V1ResetPasswordRequest,
        params: RequestParams = {},
    ) =>
        this.request<V1ResetPasswordResponse, RpcStatus>({
            path: `/auth/password${reset}`,
            method: "POST",
            body: body,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AuthService
     * @name AuthServiceSetPassword
     * @request POST:/auth/password:set
     */
    authServiceSetPassword = (
        set: string,
        body: V1SetPasswordRequest,
        params: RequestParams = {},
    ) =>
        this.request<V1SetPasswordResponse, RpcStatus>({
            path: `/auth/password${set}`,
            method: "POST",
            body: body,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AuthService
     * @name AuthServiceChangeProfileInfo
     * @request PATCH:/auth/profile-info/{id}
     * @secure
     */
    authServiceChangeProfileInfo = (
        id: string,
        body: {
            first_name?: string;
            last_name?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<Analyticsv1EmptyResponse, RpcStatus>({
            path: `/auth/profile-info/${id}`,
            method: "PATCH",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AuthService
     * @name AuthServiceDeleteUserPhoto
     * @request DELETE:/auth/profile_image
     * @secure
     */
    authServiceDeleteUserPhoto = (params: RequestParams = {}) =>
        this.request<Analyticsv1EmptyResponse, RpcStatus>({
            path: `/auth/profile_image`,
            method: "DELETE",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AuthService
     * @name AuthServiceRefreshTokens
     * @request POST:/auth/refresh-tokens
     * @secure
     */
    authServiceRefreshTokens = (
        body: V1RefreshTokensRequest,
        params: RequestParams = {},
    ) =>
        this.request<V1RefreshTokensResponse, RpcStatus>({
            path: `/auth/refresh-tokens`,
            method: "POST",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AuthService
     * @name AuthServiceResendToken
     * @request PATCH:/auth/resend-token/{user_id}
     * @secure
     */
    authServiceResendToken = (
        userId: string,
        body: object,
        params: RequestParams = {},
    ) =>
        this.request<V1ResendTokenResponse, RpcStatus>({
            path: `/auth/resend-token/${userId}`,
            method: "PATCH",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AuthService
     * @name AuthServiceListSessions
     * @request GET:/auth/sessions
     * @secure
     */
    authServiceListSessions = (params: RequestParams = {}) =>
        this.request<ListSessionsResponseSession[], RpcStatus>({
            path: `/auth/sessions`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AuthService
     * @name AuthServiceBlock
     * @request POST:/auth/users/{id}/block
     * @secure
     */
    authServiceBlock = (id: string, body: object, params: RequestParams = {}) =>
        this.request<V1BlockResponse, RpcStatus>({
            path: `/auth/users/${id}/block`,
            method: "POST",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AuthService
     * @name AuthServiceResetOtp
     * @request DELETE:/auth/users/{id}/otp
     * @secure
     */
    authServiceResetOtp = (id: string, params: RequestParams = {}) =>
        this.request<V1ResetOTPResponse, RpcStatus>({
            path: `/auth/users/${id}/otp`,
            method: "DELETE",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AuthService
     * @name AuthServiceUnblock
     * @request POST:/auth/users/{id}/unblock
     * @secure
     */
    authServiceUnblock = (
        id: string,
        body: object,
        params: RequestParams = {},
    ) =>
        this.request<V1UnblockResponse, RpcStatus>({
            path: `/auth/users/${id}/unblock`,
            method: "POST",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AuthService
     * @name AuthServiceValidateUser
     * @request POST:/auth/verify
     * @secure
     */
    authServiceValidateUser = (
        body: V1ValidateUserRequest,
        params: RequestParams = {},
    ) =>
        this.request<V1ValidateUserResponse, RpcStatus>({
            path: `/auth/verify`,
            method: "POST",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AuthService
     * @name AuthServiceConfirm
     * @request POST:/auth/verify-create
     */
    authServiceConfirm = (body: V1ConfirmRequest, params: RequestParams = {}) =>
        this.request<V1ConfirmResponse, RpcStatus>({
            path: `/auth/verify-create`,
            method: "POST",
            body: body,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AuthService
     * @name AuthServiceGetZendeskImage
     * @request GET:/auth/zendesk/article_attachments/{id}
     * @secure
     */
    authServiceGetZendeskImage = (id: string, params: RequestParams = {}) =>
        this.request<V1GetZendeskImageResponse, RpcStatus>({
            path: `/auth/zendesk/article_attachments/${id}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AuthService
     * @name AuthServiceGetZendeskArticle
     * @request GET:/auth/zendesk/articles/{id}
     * @secure
     */
    authServiceGetZendeskArticle = (id: string, params: RequestParams = {}) =>
        this.request<V1GetZendeskArticleResponse, RpcStatus>({
            path: `/auth/zendesk/articles/${id}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AuthService
     * @name AuthServiceGetZendeskJwt
     * @request GET:/auth/zendesk/sso
     * @secure
     */
    authServiceGetZendeskJwt = (params: RequestParams = {}) =>
        this.request<V1GetZendeskJWTResponse, RpcStatus>({
            path: `/auth/zendesk/sso`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
}
