import { isArray } from "lodash";

type AnyObjectType = Record<string, any>;

export const toggleDeprecatedAnalytics = <
    T extends AnyObjectType | AnyObjectType[] | string[],
>(
    element: T,
): T => {
    if (process.env.NEXT_PUBLIC_SHOW_DEPRECATED_ANALYTICS === "true") {
        return element;
    }

    if (isArray(element)) {
        return [] as unknown as T;
    } else {
        return {} as unknown as T;
    }
};
