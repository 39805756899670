/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    Analyticsv1EmptyResponse,
    RpcStatus,
    V1CreateFolderRequest,
    V1CreateFolderResponse,
    V1CreatePublicReportRequest,
    V1CreatePublicReportResponse,
    V1CreateReportLabelRequest,
    V1CreateReportLabelResponse,
    V1CreateReportRequest,
    V1CreateReportV2ByAggUserRequest,
    V1CreateReportV2ByAggUserResponse,
    V1Dashboard,
    V1DeleteReportLabelResponse,
    V1GetAccessByAggUserForAggCompanyResponse,
    V1GetAccessByAggUserForB2BProviderCompaniesResponse,
    V1GetAccessByB2BProviderUserResponse,
    V1GetAccessesForReportResponse,
    V1GetAccessesResponse,
    V1GetDashboardGuestTokenResponse,
    V1GetExportReportsResponse,
    V1GetFoldersResponse,
    V1GetFoldersV2AGGResponse,
    V1GetFoldersV2Response,
    V1GetPublicReportResponse,
    V1GetReportsLabelsResponse,
    V1GrantRevokePermission,
    V1GrantRevokePermissionReportResponse,
    V1GrantRevokePermissionReportV2Response,
    V1GrantRevokeReportsLabelsForReportResponse,
    V1ListReportResponse,
    V1ListReportV2Response,
    V1ListReportsByAggUserForAggCompanyResponse,
    V1ListReportsByAggUserForAggCompanyV2Response,
    V1ListReportsByAggUserForB2BProviderCompaniesResponse,
    V1ListReportsByAggUserForB2BVendorCompaniesV2Response,
    V1ListReportsByB2BProviderUserResponse,
    V1ListReportsByB2BProviderUserV2Response,
    V1ListUsersReportResponse,
    V1PowerBIEmbeddedToken,
    V1Report,
    V1ReportV2,
    V1ReportWithVendor,
    V1UpdateFolderResponse,
    V1UpdateReportLabelResponse,
    V1UpdateReportV2ByAggUserForAggCompanyResponse,
    V1UpdateReportV2ByAggUserForB2BProviderCompaniesResponse,
    V1UpdateReportV2ByB2BVendorUserResponse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Analytics<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceGetFoldersV2Agg
     * @request GET:/analytics/agg-user/folders
     * @secure
     */
    analyticsServiceGetFoldersV2Agg = (
        query: {
            type: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1GetFoldersV2AGGResponse, RpcStatus>({
            path: `/analytics/agg-user/folders`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceGetFolders
     * @request GET:/analytics/folders
     * @secure
     */
    analyticsServiceGetFolders = (
        query?: {
            b2b_company_id?: string;
            vendor_id?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1GetFoldersResponse, RpcStatus>({
            path: `/analytics/folders`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceCreateFolder
     * @request POST:/analytics/folders
     * @secure
     */
    analyticsServiceCreateFolder = (
        body: V1CreateFolderRequest,
        params: RequestParams = {},
    ) =>
        this.request<V1CreateFolderResponse, RpcStatus>({
            path: `/analytics/folders`,
            method: "POST",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceUpdateFolder
     * @request PATCH:/analytics/folders/{folder_id}
     * @secure
     */
    analyticsServiceUpdateFolder = (
        folderId: string,
        body: {
            parent_id?: string;
            name?: string;
            is_deleted?: boolean;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1UpdateFolderResponse, RpcStatus>({
            path: `/analytics/folders/${folderId}`,
            method: "PATCH",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceGetReportsLabels
     * @request GET:/analytics/labels
     * @secure
     */
    analyticsServiceGetReportsLabels = (
        query?: {
            /** @format int64 */
            page_size?: number;
            /** @format int64 */
            page_number?: number;
            title?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1GetReportsLabelsResponse, RpcStatus>({
            path: `/analytics/labels`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceCreateReportLabel
     * @request POST:/analytics/labels
     * @secure
     */
    analyticsServiceCreateReportLabel = (
        body: V1CreateReportLabelRequest,
        params: RequestParams = {},
    ) =>
        this.request<V1CreateReportLabelResponse, RpcStatus>({
            path: `/analytics/labels`,
            method: "POST",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceDeleteReportLabel
     * @request DELETE:/analytics/labels/{reports_labels_id}
     * @secure
     */
    analyticsServiceDeleteReportLabel = (
        reportsLabelsId: string,
        params: RequestParams = {},
    ) =>
        this.request<V1DeleteReportLabelResponse, RpcStatus>({
            path: `/analytics/labels/${reportsLabelsId}`,
            method: "DELETE",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceUpdateReportLabel
     * @request PATCH:/analytics/labels/{reports_labels_id}
     * @secure
     */
    analyticsServiceUpdateReportLabel = (
        reportsLabelsId: string,
        body: {
            title?: string;
            description?: string;
            formula?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1UpdateReportLabelResponse, RpcStatus>({
            path: `/analytics/labels/${reportsLabelsId}`,
            method: "PATCH",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceCreatePublicReport
     * @request POST:/analytics/public-reports
     * @secure
     */
    analyticsServiceCreatePublicReport = (
        body: V1CreatePublicReportRequest,
        params: RequestParams = {},
    ) =>
        this.request<V1CreatePublicReportResponse, RpcStatus>({
            path: `/analytics/public-reports`,
            method: "POST",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceGetPublicReport
     * @request GET:/analytics/public-reports/{public_report_id}
     * @secure
     */
    analyticsServiceGetPublicReport = (
        publicReportId: string,
        params: RequestParams = {},
    ) =>
        this.request<V1GetPublicReportResponse, RpcStatus>({
            path: `/analytics/public-reports/${publicReportId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceUpdatePublicReport
     * @request PATCH:/analytics/public-reports/{public_report_id}
     * @secure
     */
    analyticsServiceUpdatePublicReport = (
        publicReportId: string,
        body: {
            name?: string;
            description?: string;
            public_id_pbi?: string;
            is_deleted?: boolean;
        },
        params: RequestParams = {},
    ) =>
        this.request<Analyticsv1EmptyResponse, RpcStatus>({
            path: `/analytics/public-reports/${publicReportId}`,
            method: "PATCH",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceCreateReport
     * @request POST:/analytics/reports
     * @secure
     */
    analyticsServiceCreateReport = (
        body: V1CreateReportRequest,
        params: RequestParams = {},
    ) =>
        this.request<V1Report, RpcStatus>({
            path: `/analytics/reports`,
            method: "POST",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceGetAccessByAggUserForAggCompany
     * @request GET:/analytics/reports-access/agg-user/agg-company/{user_id}
     * @secure
     */
    analyticsServiceGetAccessByAggUserForAggCompany = (
        userId: string,
        params: RequestParams = {},
    ) =>
        this.request<V1GetAccessByAggUserForAggCompanyResponse, RpcStatus>({
            path: `/analytics/reports-access/agg-user/agg-company/${userId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceGetAccessByAggUserForB2BProviderCompanies
     * @request GET:/analytics/reports-access/agg-user/b2b-vendor-company/{user_id}
     * @secure
     */
    analyticsServiceGetAccessByAggUserForB2BProviderCompanies = (
        userId: string,
        params: RequestParams = {},
    ) =>
        this.request<
            V1GetAccessByAggUserForB2BProviderCompaniesResponse,
            RpcStatus
        >({
            path: `/analytics/reports-access/agg-user/b2b-vendor-company/${userId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceGetAccessByB2BProviderUser
     * @request GET:/analytics/reports-access/b2b-vendor-user/{user_id}
     * @secure
     */
    analyticsServiceGetAccessByB2BProviderUser = (
        userId: string,
        params: RequestParams = {},
    ) =>
        this.request<V1GetAccessByB2BProviderUserResponse, RpcStatus>({
            path: `/analytics/reports-access/b2b-vendor-user/${userId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceGrantRevokeReportsLabelsForReport
     * @request PATCH:/analytics/reports-labels-links/{report_id}
     * @secure
     */
    analyticsServiceGrantRevokeReportsLabelsForReport = (
        reportId: string,
        body: {
            grant?: string[];
            revoke?: string[];
        },
        params: RequestParams = {},
    ) =>
        this.request<V1GrantRevokeReportsLabelsForReportResponse, RpcStatus>({
            path: `/analytics/reports-labels-links/${reportId}`,
            method: "PATCH",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceListReportsByAggUserForAggCompany
     * @request GET:/analytics/reports/agg-user/agg-company
     * @secure
     */
    analyticsServiceListReportsByAggUserForAggCompany = (
        params: RequestParams = {},
    ) =>
        this.request<V1ListReportsByAggUserForAggCompanyResponse, RpcStatus>({
            path: `/analytics/reports/agg-user/agg-company`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceUpdateReportByAggUserForAggCompany
     * @request PATCH:/analytics/reports/agg-user/agg-company/{report_id}
     * @secure
     */
    analyticsServiceUpdateReportByAggUserForAggCompany = (
        reportId: string,
        body: {
            folder_id?: string;
            report_name?: string;
            description?: string;
            report_id_pbi?: string;
            status?: boolean;
            is_deleted?: boolean;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1Report, RpcStatus>({
            path: `/analytics/reports/agg-user/agg-company/${reportId}`,
            method: "PATCH",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceListReportsByAggUserForB2BProviderCompanies
     * @request GET:/analytics/reports/agg-user/b2b-provider-company
     * @secure
     */
    analyticsServiceListReportsByAggUserForB2BProviderCompanies = (
        query?: {
            b2b_company_id?: string;
            provider_id?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<
            V1ListReportsByAggUserForB2BProviderCompaniesResponse,
            RpcStatus
        >({
            path: `/analytics/reports/agg-user/b2b-provider-company`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceUpdateReportByAggUserForB2BProviderCompanies
     * @request PATCH:/analytics/reports/agg-user/b2b-provider-company/{report_id}
     * @secure
     */
    analyticsServiceUpdateReportByAggUserForB2BProviderCompanies = (
        reportId: string,
        body: {
            folder_id?: string;
            report_name?: string;
            description?: string;
            report_id_pbi?: string;
            status?: boolean;
            is_deleted?: boolean;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1Report, RpcStatus>({
            path: `/analytics/reports/agg-user/b2b-provider-company/${reportId}`,
            method: "PATCH",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceListReportsByB2BProviderUser
     * @request GET:/analytics/reports/b2b-provider-user
     * @secure
     */
    analyticsServiceListReportsByB2BProviderUser = (
        query?: {
            b2b_company_id?: string;
            provider_id?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1ListReportsByB2BProviderUserResponse, RpcStatus>({
            path: `/analytics/reports/b2b-provider-user`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceUpdateReportByB2BProviderUser
     * @request PATCH:/analytics/reports/b2b-provider-user/{report_id}
     * @secure
     */
    analyticsServiceUpdateReportByB2BProviderUser = (
        reportId: string,
        body: {
            status?: boolean;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1Report, RpcStatus>({
            path: `/analytics/reports/b2b-provider-user/${reportId}`,
            method: "PATCH",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceGetExportReports
     * @request GET:/analytics/reports/export-types
     * @secure
     */
    analyticsServiceGetExportReports = (
        query: {
            company_type: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1GetExportReportsResponse, RpcStatus>({
            path: `/analytics/reports/export-types`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceGetAllSupersetDashboards
     * @request GET:/analytics/superset/all-reports
     * @secure
     */
    analyticsServiceGetAllSupersetDashboards = (
        query?: {
            dashboard_title?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1Dashboard[], RpcStatus>({
            path: `/analytics/superset/all-reports`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceGetDashboardGuestToken
     * @request GET:/analytics/superset/report/{dashboard_id}
     * @secure
     */
    analyticsServiceGetDashboardGuestToken = (
        dashboardId: string,
        params: RequestParams = {},
    ) =>
        this.request<V1GetDashboardGuestTokenResponse, RpcStatus>({
            path: `/analytics/superset/report/${dashboardId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceUpdateRolesAssignToUsers
     * @request POST:/analytics/superset/superset-role/{superset_role}/users
     * @secure
     */
    analyticsServiceUpdateRolesAssignToUsers = (
        supersetRole: string,
        body: {
            grant?: string[];
            revoke?: string[];
        },
        params: RequestParams = {},
    ) =>
        this.request<Analyticsv1EmptyResponse, RpcStatus>({
            path: `/analytics/superset/superset-role/${supersetRole}/users`,
            method: "POST",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceGetSupersetDashboardsForUser
     * @request GET:/analytics/superset/view/reports
     * @secure
     */
    analyticsServiceGetSupersetDashboardsForUser = (
        query?: {
            dashboard_title?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1Dashboard[], RpcStatus>({
            path: `/analytics/superset/view/reports`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceListUsersReportAggByAgg
     * @request GET:/analytics/users/agg-user/agg-company/{report_id}
     * @secure
     */
    analyticsServiceListUsersReportAggByAgg = (
        reportId: string,
        params: RequestParams = {},
    ) =>
        this.request<V1ListUsersReportResponse, RpcStatus>({
            path: `/analytics/users/agg-user/agg-company/${reportId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceGrantRevokePermissionReportByAggToAgg
     * @request POST:/analytics/users/agg-user/agg-company/{report_id}
     * @secure
     */
    analyticsServiceGrantRevokePermissionReportByAggToAgg = (
        reportId: string,
        body: {
            grant?: V1GrantRevokePermission[];
            revoke?: V1GrantRevokePermission[];
        },
        params: RequestParams = {},
    ) =>
        this.request<V1GrantRevokePermissionReportResponse, RpcStatus>({
            path: `/analytics/users/agg-user/agg-company/${reportId}`,
            method: "POST",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceListUsersReportB2BOrVendorByAgg
     * @request GET:/analytics/users/agg-user/b2b-vendor-company/{report_id}
     * @secure
     */
    analyticsServiceListUsersReportB2BOrVendorByAgg = (
        reportId: string,
        params: RequestParams = {},
    ) =>
        this.request<V1ListUsersReportResponse, RpcStatus>({
            path: `/analytics/users/agg-user/b2b-vendor-company/${reportId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceGrantRevokePermissionReportByAggToB2BOrVendor
     * @request POST:/analytics/users/agg-user/b2b-vendor-company/{report_id}
     * @secure
     */
    analyticsServiceGrantRevokePermissionReportByAggToB2BOrVendor = (
        reportId: string,
        body: {
            grant?: V1GrantRevokePermission[];
            revoke?: V1GrantRevokePermission[];
        },
        params: RequestParams = {},
    ) =>
        this.request<V1GrantRevokePermissionReportResponse, RpcStatus>({
            path: `/analytics/users/agg-user/b2b-vendor-company/${reportId}`,
            method: "POST",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceListUsersReportByB2BOrVendor
     * @request GET:/analytics/users/b2b-vendor-user/{report_id}
     * @secure
     */
    analyticsServiceListUsersReportByB2BOrVendor = (
        reportId: string,
        params: RequestParams = {},
    ) =>
        this.request<V1ListUsersReportResponse, RpcStatus>({
            path: `/analytics/users/b2b-vendor-user/${reportId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceGrantRevokePermissionReportByB2BOrVendor
     * @request POST:/analytics/users/b2b-vendor-user/{report_id}
     * @secure
     */
    analyticsServiceGrantRevokePermissionReportByB2BOrVendor = (
        reportId: string,
        body: {
            grant?: V1GrantRevokePermission[];
            revoke?: V1GrantRevokePermission[];
        },
        params: RequestParams = {},
    ) =>
        this.request<V1GrantRevokePermissionReportResponse, RpcStatus>({
            path: `/analytics/users/b2b-vendor-user/${reportId}`,
            method: "POST",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceGrantRevokePermissionReportByAggToAggReverse
     * @request POST:/analytics/users/reverse/agg-user/agg-company/{user_id}
     * @secure
     */
    analyticsServiceGrantRevokePermissionReportByAggToAggReverse = (
        userId: string,
        body: {
            grant?: string[];
            revoke?: string[];
        },
        params: RequestParams = {},
    ) =>
        this.request<V1GrantRevokePermissionReportV2Response, RpcStatus>({
            path: `/analytics/users/reverse/agg-user/agg-company/${userId}`,
            method: "POST",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceGrantRevokePermissionReportByAggToB2BOrVendorReverse
     * @request POST:/analytics/users/reverse/agg-user/b2b-vendor-company/{user_id}
     * @secure
     */
    analyticsServiceGrantRevokePermissionReportByAggToB2BOrVendorReverse = (
        userId: string,
        body: {
            grant?: string[];
            revoke?: string[];
        },
        params: RequestParams = {},
    ) =>
        this.request<V1GrantRevokePermissionReportV2Response, RpcStatus>({
            path: `/analytics/users/reverse/agg-user/b2b-vendor-company/${userId}`,
            method: "POST",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceGrantRevokePermissionReportByB2BOrVendorReverse
     * @request POST:/analytics/users/reverse/b2b-vendor-user/{user_id}
     * @secure
     */
    analyticsServiceGrantRevokePermissionReportByB2BOrVendorReverse = (
        userId: string,
        body: {
            grant?: string[];
            revoke?: string[];
        },
        params: RequestParams = {},
    ) =>
        this.request<V1GrantRevokePermissionReportV2Response, RpcStatus>({
            path: `/analytics/users/reverse/b2b-vendor-user/${userId}`,
            method: "POST",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceGetFoldersV2
     * @request GET:/analytics/v2/folders
     * @secure
     */
    analyticsServiceGetFoldersV2 = (
        query: {
            type: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1GetFoldersV2Response, RpcStatus>({
            path: `/analytics/v2/folders`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceCreateReportV2ByAggUser
     * @request POST:/analytics/v2/reports
     * @secure
     */
    analyticsServiceCreateReportV2ByAggUser = (
        body: V1CreateReportV2ByAggUserRequest,
        params: RequestParams = {},
    ) =>
        this.request<V1CreateReportV2ByAggUserResponse, RpcStatus>({
            path: `/analytics/v2/reports`,
            method: "POST",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceListReportsByAggUserForAggCompanyV2
     * @request GET:/analytics/v2/reports/agg-user/agg-company
     * @secure
     */
    analyticsServiceListReportsByAggUserForAggCompanyV2 = (
        query?: {
            report_type?: string;
            status?: boolean;
            report_label_ids?: string[];
        },
        params: RequestParams = {},
    ) =>
        this.request<V1ListReportsByAggUserForAggCompanyV2Response, RpcStatus>({
            path: `/analytics/v2/reports/agg-user/agg-company`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceUpdateReportV2ByAggUserForAggCompany
     * @request PATCH:/analytics/v2/reports/agg-user/agg-company/{report_id}
     * @secure
     */
    analyticsServiceUpdateReportV2ByAggUserForAggCompany = (
        reportId: string,
        body: {
            folder_id?: string;
            report_name?: string;
            description?: string;
            report_id_pbi?: string;
            status?: boolean;
            is_deleted?: boolean;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1UpdateReportV2ByAggUserForAggCompanyResponse, RpcStatus>(
            {
                path: `/analytics/v2/reports/agg-user/agg-company/${reportId}`,
                method: "PATCH",
                body: body,
                secure: true,
                format: "json",
                ...params,
            },
        );
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceListReportsByAggUserForB2BVendorCompaniesV2
     * @request GET:/analytics/v2/reports/agg-user/b2b-provider-company
     * @secure
     */
    analyticsServiceListReportsByAggUserForB2BVendorCompaniesV2 = (
        query?: {
            b2b_company_root_id?: string;
            vendor_id?: string;
            report_type?: string;
            status?: boolean;
            report_label_ids?: string[];
        },
        params: RequestParams = {},
    ) =>
        this.request<
            V1ListReportsByAggUserForB2BVendorCompaniesV2Response,
            RpcStatus
        >({
            path: `/analytics/v2/reports/agg-user/b2b-provider-company`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceUpdateReportV2ByAggUserForB2BProviderCompanies
     * @request PATCH:/analytics/v2/reports/agg-user/b2b-provider-company/{report_id}
     * @secure
     */
    analyticsServiceUpdateReportV2ByAggUserForB2BProviderCompanies = (
        reportId: string,
        body: {
            folder_id?: string;
            report_name?: string;
            description?: string;
            report_id_pbi?: string;
            status?: boolean;
            is_deleted?: boolean;
            export_type?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<
            V1UpdateReportV2ByAggUserForB2BProviderCompaniesResponse,
            RpcStatus
        >({
            path: `/analytics/v2/reports/agg-user/b2b-provider-company/${reportId}`,
            method: "PATCH",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceListReportsByB2BProviderUserV2
     * @request GET:/analytics/v2/reports/b2b-provider-user
     * @secure
     */
    analyticsServiceListReportsByB2BProviderUserV2 = (
        query?: {
            report_type?: string;
            status?: boolean;
            report_label_ids?: string[];
        },
        params: RequestParams = {},
    ) =>
        this.request<V1ListReportsByB2BProviderUserV2Response, RpcStatus>({
            path: `/analytics/v2/reports/b2b-provider-user`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceUpdateReportV2ByB2BVendorUser
     * @request PATCH:/analytics/v2/reports/b2b-provider-user/{report_id}
     * @secure
     */
    analyticsServiceUpdateReportV2ByB2BVendorUser = (
        reportId: string,
        body: {
            status?: boolean;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1UpdateReportV2ByB2BVendorUserResponse, RpcStatus>({
            path: `/analytics/v2/reports/b2b-provider-user/${reportId}`,
            method: "PATCH",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceGetAccessesAggForReport
     * @request GET:/analytics/v2/users/agg-user/agg-company/{report_id}
     * @secure
     */
    analyticsServiceGetAccessesAggForReport = (
        reportId: string,
        params: RequestParams = {},
    ) =>
        this.request<V1GetAccessesResponse, RpcStatus>({
            path: `/analytics/v2/users/agg-user/agg-company/${reportId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceGrantRevokePermissionReportByAggToAggV2
     * @request POST:/analytics/v2/users/agg-user/agg-company/{report_id}
     * @secure
     */
    analyticsServiceGrantRevokePermissionReportByAggToAggV2 = (
        reportId: string,
        body: {
            grant?: string[];
            revoke?: string[];
        },
        params: RequestParams = {},
    ) =>
        this.request<V1GrantRevokePermissionReportV2Response, RpcStatus>({
            path: `/analytics/v2/users/agg-user/agg-company/${reportId}`,
            method: "POST",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceGetAccessesForReport
     * @request GET:/analytics/v2/users/agg-user/b2b-vendor-company/{report_id}
     * @secure
     */
    analyticsServiceGetAccessesForReport = (
        reportId: string,
        params: RequestParams = {},
    ) =>
        this.request<V1GetAccessesForReportResponse, RpcStatus>({
            path: `/analytics/v2/users/agg-user/b2b-vendor-company/${reportId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceGrantRevokePermissionReportByAggToB2BOrVendorV2
     * @request POST:/analytics/v2/users/agg-user/b2b-vendor-company/{report_id}
     * @secure
     */
    analyticsServiceGrantRevokePermissionReportByAggToB2BOrVendorV2 = (
        reportId: string,
        body: {
            grant?: string[];
            revoke?: string[];
        },
        params: RequestParams = {},
    ) =>
        this.request<V1GrantRevokePermissionReportV2Response, RpcStatus>({
            path: `/analytics/v2/users/agg-user/b2b-vendor-company/${reportId}`,
            method: "POST",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceGetAccessesB2BVendorForReport
     * @request GET:/analytics/v2/users/b2b-vendor-user/{report_id}
     * @secure
     */
    analyticsServiceGetAccessesB2BVendorForReport = (
        reportId: string,
        params: RequestParams = {},
    ) =>
        this.request<V1GetAccessesResponse, RpcStatus>({
            path: `/analytics/v2/users/b2b-vendor-user/${reportId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceGrantRevokePermissionReportByB2BOrVendorV2
     * @request POST:/analytics/v2/users/b2b-vendor-user/{report_id}
     * @secure
     */
    analyticsServiceGrantRevokePermissionReportByB2BOrVendorV2 = (
        reportId: string,
        body: {
            grant?: string[];
            revoke?: string[];
        },
        params: RequestParams = {},
    ) =>
        this.request<V1GrantRevokePermissionReportV2Response, RpcStatus>({
            path: `/analytics/v2/users/b2b-vendor-user/${reportId}`,
            method: "POST",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceGetPowerBiEmbeddedTokenV2
     * @request GET:/analytics/v2/view/embedded-token/{report_id}
     * @secure
     */
    analyticsServiceGetPowerBiEmbeddedTokenV2 = (
        reportId: string,
        params: RequestParams = {},
    ) =>
        this.request<V1PowerBIEmbeddedToken, RpcStatus>({
            path: `/analytics/v2/view/embedded-token/${reportId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceListReportV2
     * @request GET:/analytics/v2/view/reports
     * @secure
     */
    analyticsServiceListReportV2 = (
        query?: {
            report_type?: string;
            status?: boolean;
            report_label_ids?: string[];
        },
        params: RequestParams = {},
    ) =>
        this.request<V1ListReportV2Response, RpcStatus>({
            path: `/analytics/v2/view/reports`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceGetReportV2
     * @request GET:/analytics/v2/view/reports/{report_id}
     * @secure
     */
    analyticsServiceGetReportV2 = (
        reportId: string,
        params: RequestParams = {},
    ) =>
        this.request<V1ReportV2, RpcStatus>({
            path: `/analytics/v2/view/reports/${reportId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceGetPowerBiEmbeddedToken
     * @request GET:/analytics/view/embedded-token/{report_id_pbi}
     * @secure
     */
    analyticsServiceGetPowerBiEmbeddedToken = (
        reportIdPbi: string,
        params: RequestParams = {},
    ) =>
        this.request<V1PowerBIEmbeddedToken, RpcStatus>({
            path: `/analytics/view/embedded-token/${reportIdPbi}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceListReport
     * @request GET:/analytics/view/reports
     * @secure
     */
    analyticsServiceListReport = (
        query?: {
            b2b_company_id?: string;
            vendor_id?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1ListReportResponse, RpcStatus>({
            path: `/analytics/view/reports`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags AnalyticsService
     * @name AnalyticsServiceGetReport
     * @request GET:/analytics/view/reports/{report_id}
     * @secure
     */
    analyticsServiceGetReport = (
        reportId: string,
        params: RequestParams = {},
    ) =>
        this.request<V1ReportWithVendor, RpcStatus>({
            path: `/analytics/view/reports/${reportId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
}
