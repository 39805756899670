/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    Analyticsv1EmptyResponse,
    RpcStatus,
    Settingsv1Api,
    Settingsv1Client,
    V1CreateClientRequest,
    V1GetWhiteListResponse,
    V1ListClientResponse,
    V1ListWhiteListsResponse,
    V1ShortClient,
    V1WhiteList,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Settings<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags SettingsService
     * @name SettingsServiceGetClient
     * @request GET:/settings/client/{client_id}
     * @secure
     */
    settingsServiceGetClient = (clientId: string, params: RequestParams = {}) =>
        this.request<Settingsv1Client, RpcStatus>({
            path: `/settings/client/${clientId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags SettingsService
     * @name SettingsServiceUpdateClient
     * @request PATCH:/settings/client/{client_id}
     * @secure
     */
    settingsServiceUpdateClient = (
        clientId: string,
        body: {
            label: string;
            description?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<Analyticsv1EmptyResponse, RpcStatus>({
            path: `/settings/client/${clientId}`,
            method: "PATCH",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags SettingsService
     * @name SettingsServiceActivationClient
     * @request PUT:/settings/client/{client_id}/activation
     * @secure
     */
    settingsServiceActivationClient = (
        clientId: string,
        body: {
            is_active: boolean;
        },
        params: RequestParams = {},
    ) =>
        this.request<Analyticsv1EmptyResponse, RpcStatus>({
            path: `/settings/client/${clientId}/activation`,
            method: "PUT",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags SettingsService
     * @name SettingsServiceListClient
     * @request GET:/settings/clients
     * @secure
     */
    settingsServiceListClient = (
        query?: {
            /** @format int64 */
            page_number?: number;
            /** @format int64 */
            page_size?: number;
            client_label?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1ListClientResponse, RpcStatus>({
            path: `/settings/clients`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags SettingsService
     * @name SettingsServiceCreateClient
     * @request POST:/settings/new-client
     * @secure
     */
    settingsServiceCreateClient = (
        body: V1CreateClientRequest,
        params: RequestParams = {},
    ) =>
        this.request<Settingsv1Client, RpcStatus>({
            path: `/settings/new-client`,
            method: "POST",
            body: body,
            secure: true,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags SettingsService
     * @name SettingsServiceListNoRelationsApis
     * @request GET:/settings/no-relations/apis
     * @secure
     */
    settingsServiceListNoRelationsApis = (
        query?: {
            client_id?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<Settingsv1Api[], RpcStatus>({
            path: `/settings/no-relations/apis`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags SettingsService
     * @name SettingsServiceListNoRelationsClients
     * @request GET:/settings/no-relations/clients
     * @secure
     */
    settingsServiceListNoRelationsClients = (
        query: {
            api_id: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1ShortClient[], RpcStatus>({
            path: `/settings/no-relations/clients`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags SettingsService
     * @name SettingsServiceGetWhiteList
     * @request GET:/settings/whitelist/{api_id}/{client_id}
     * @secure
     */
    settingsServiceGetWhiteList = (
        apiId: string,
        clientId: string,
        params: RequestParams = {},
    ) =>
        this.request<V1GetWhiteListResponse, RpcStatus>({
            path: `/settings/whitelist/${apiId}/${clientId}`,
            method: "GET",
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags SettingsService
     * @name SettingsServiceCreateWhitelist
     * @request POST:/settings/whitelist/{api_id}/{client_id}
     * @secure
     */
    settingsServiceCreateWhitelist = (
        apiId: string,
        clientId: string,
        body: {
            ips: string[];
        },
        params: RequestParams = {},
    ) =>
        this.request<V1WhiteList, RpcStatus>({
            path: `/settings/whitelist/${apiId}/${clientId}`,
            method: "POST",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags SettingsService
     * @name SettingsServiceUpdateWhitelist
     * @request PATCH:/settings/whitelist/{api_id}/{client_id}
     * @secure
     */
    settingsServiceUpdateWhitelist = (
        apiId: string,
        clientId: string,
        body: {
            ips: string[];
        },
        params: RequestParams = {},
    ) =>
        this.request<Analyticsv1EmptyResponse, RpcStatus>({
            path: `/settings/whitelist/${apiId}/${clientId}`,
            method: "PATCH",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags SettingsService
     * @name SettingsServiceActivationWhitelist
     * @request PATCH:/settings/whitelist/{api_id}/{client_id}/activation
     * @secure
     */
    settingsServiceActivationWhitelist = (
        apiId: string,
        clientId: string,
        body: {
            is_active: boolean;
        },
        params: RequestParams = {},
    ) =>
        this.request<Analyticsv1EmptyResponse, RpcStatus>({
            path: `/settings/whitelist/${apiId}/${clientId}/activation`,
            method: "PATCH",
            body: body,
            secure: true,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags SettingsService
     * @name SettingsServiceListWhiteLists
     * @request GET:/settings/whitelists
     * @secure
     */
    settingsServiceListWhiteLists = (
        query?: {
            /** @format int64 */
            page_number?: number;
            /** @format int64 */
            page_size?: number;
            client_ids?: string[];
            api_ids?: string[];
            is_active?: boolean;
        },
        params: RequestParams = {},
    ) =>
        this.request<V1ListWhiteListsResponse, RpcStatus>({
            path: `/settings/whitelists`,
            method: "GET",
            query: query,
            secure: true,
            format: "json",
            ...params,
        });
}
